export default {
  balances: (state) => state.balances,
  commission: (state) => state.commission,
  delegations: (state) => state.delegations,
  delegationsNextKey: (state) => state.delegationsPagination?.next_key,
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  isAddingTxs: (state) => state.isAddingTxs,
  membership: (state) => state.membership,
  membershipTxs: (state) => state.membershipTxs,
  rewards: (state) => state.rewards,
  transactions: (state) => state.transactions,
  transactionsOffset: (state) => state.transactionsOffset,
  transactionsTotal: (state) => state.transactionsPagination.total,
  unbondings: (state) => state.unbondings,
  unbondingsNextKey: (state) => state.unbondingsPagination?.next_key,
};
