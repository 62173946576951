const NAME = Object.freeze({
  NOT_FOUND: 'notFound',
  ACCOUNT: 'account',
  ACCOUNT_BALANCE: 'accountBalance',
  ACCOUNT_DASHBOARD: 'accountDashboard',
  ASSETS: 'assets',
  ASSETS_LIST: 'assetsList',
  ASSETS_DETAIL: 'assetsDetail',
  ASSETS_DETAIL_INFO: 'assetsDetailInfo',
  ASSETS_DETAIL_TRANSFER: 'assetsDetailTransfer',
  ASSETS_NEW: 'assetsNew',
  BLOCKS: 'blocks',
  BLOCKS_LIST: 'blocksList',
  BLOCKS_DETAIL: 'blocksDetail',
  DEX: 'dex',
  POOLS: 'pools',
  POOLS_LIST: 'poolsList',
  POOLS_DETAIL: 'poolsDetail',
  POOLS_DETAIL_DASHBOARD: 'poolsDetailDashboard',
  POOLS_DETAIL_SWAP: 'poolsDetailSwap',
  HOME: 'home',
  EXCHANGE_RATE: 'exchangeRate',
  PROPOSALS: 'proposals',
  PROPOSALS_LIST: 'proposalsList',
  PROPOSALS_DETAIL: 'proposalsDetail',
  STATS: 'stats',
  STATS_ACCOUNTS_BALANCE: 'statsAccountsBalance',
  STATS_DASHBOARD: 'statsDashboard',
  TRANSACTIONS: 'transactions',
  TRANSACTIONS_LIST: 'transactionsList',
  TRANSACTIONS_DETAIL: 'transactionsDetail',
  TRANSACTIONS_SHAREDOC: 'transactionsSharedoc',
  VALIDATORS: 'validators',
  VALIDATORS_LIST: 'validatorsList',
  VALIDATORS_DETAIL: 'validatorsDetail',
  WORK_IN_PROGRESS: 'workInProgress',
});

const PATH = Object.freeze({
  ROOT: '/',
  NOT_FOUND: '/404',
  ACCOUNT: '/account/:id',
  ACCOUNT_BALANCE: 'balance',
  ACCOUNT_DASHBOARD: 'dashboard',
  ASSETS: '/assets',
  ASSETS_LIST: 'list',
  ASSETS_DETAIL: 'detail/:id',
  ASSETS_DETAIL_INFO: 'info',
  ASSETS_DETAIL_TRANSFER: 'transfer',
  ASSETS_NEW: 'new-contract/cw20',
  BLOCKS: '/blocks',
  BLOCKS_LIST: 'list',
  BLOCKS_DETAIL: 'detail/:id',
  DEX: '/dex',
  POOLS: '/pools',
  POOLS_LIST: 'list',
  POOLS_DETAIL: 'detail/:id',
  POOLS_DETAIL_DASHBOARD: 'dashboard',
  POOLS_DETAIL_SWAP: 'swap',
  HOME: '/home',
  EXCHANGE_RATE: '/exchange-rate',
  PROPOSALS: '/proposals',
  PROPOSALS_LIST: 'list',
  PROPOSALS_DETAIL: 'detail/:id',
  STATS: '/stats',
  STATS_ACCOUNTS_BALANCE: 'accounts/balance/33ns3x2qtijz',
  STATS_DASHBOARD: 'dashboard',
  TRANSACTIONS: '/transactions',
  TRANSACTIONS_LIST: 'list',
  TRANSACTIONS_DETAIL: 'detail/:id',
  TRANSACTIONS_SHAREDOC: 'sharedoc/:hash/uuid/:uuid',
  VALIDATORS: '/validators',
  VALIDATORS_LIST: 'list',
  VALIDATORS_DETAIL: 'detail/:id',
  WORK_IN_PROGRESS: '/work-in-progress',
});

const TITLE = Object.freeze({
  NOT_FOUND: 'Not Found',
  ACCOUNT: 'Account',
  ACCOUNT_BALANCE: 'Account Balance',
  ACCOUNT_DASHBOARD: 'Account Dashboard',
  ASSETS: 'Assets',
  ASSETS_LIST: 'Assets List',
  ASSETS_DETAIL: 'Assets Detail',
  ASSETS_DETAIL_INFO: 'Assets Detail Info',
  ASSETS_DETAIL_TRANSFER: 'Assets Detail Transfer',
  ASSETS_NEW: 'Assets New',
  BLOCKS: 'Blocks',
  BLOCKS_LIST: 'Blocks List',
  BLOCKS_DETAIL: 'Blocks Detail',
  DEX: 'dex',
  POOLS: 'pools',
  POOLS_LIST: 'Pools List',
  POOLS_DETAIL: 'Pools Detail',
  POOLS_DETAIL_DASHBOARD: 'Pools Detail Dashboard',
  POOLS_DETAIL_SWAP: 'Pools Detail Swap',
  HOME: 'Home',
  EXCHANGE_RATE: 'Exchange Rate',
  PROPOSALS: 'Proposals',
  PROPOSALS_LIST: 'Proposals List',
  PROPOSALS_DETAIL: 'Proposals Detail',
  STATS: 'Statistics',
  STATS_ACCOUNTS_BALANCE: 'Stats Accounts Balance',
  STATS_DASHBOARD: 'Stats Dashboard',
  TRANSACTIONS: 'Transactions',
  TRANSACTIONS_LIST: 'Transactions List',
  TRANSACTIONS_DETAIL: 'Transactions Detail',
  TRANSACTIONS_SHAREDOC: 'Transactions Sharedoc',
  VALIDATORS: 'Validators',
  VALIDATORS_LIST: 'Validators List',
  VALIDATORS_DETAIL: 'Validators Detail',
  WORK_IN_PROGRESS: 'Work In Progress',
});

export default Object.freeze({
  NAME,
  PATH,
  TITLE,
});
