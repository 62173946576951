export const titles = {
  accountBalance: 'Account balance',
  accountDetail: 'Account detail',
  accountsBalance: 'Accounts balance',
  activeValidatorsList: 'Active validators list',
  allTokensBalance: 'All tokens balance',
  allWallets: 'All wallets',
  assetDetail: 'Asset detail',
  assetTransfer: 'Asset transfer',
  assets: 'Assets',
  blockDetail: 'Block detail',
  blocks: 'Blocks',
  blocksFromTo: 'Blocks from {0} to {1}',
  cccExchangeRate: 'CCC exchange rate',
  checksum: 'Checksum',
  code: 'Code',
  comCapitalization: 'COM Capitalization',
  commission: 'Commission',
  contractDetail: 'Contract detail',
  countdownForBlock: 'Countdown for block',
  dashboard: 'Dashboard',
  delegations: 'Delegations',
  delegatorAmounts: 'Delegator amounts',
  deposit: 'Deposit',
  description: 'Description',
  dex: 'Dex',
  dexDetail: 'Dex detail',
  dexList: 'Dex List',
  doSign: 'Do sign',
  editLogo: 'Edit logo',
  editMarketing: 'Edit marketing',
  exchangeRate: 'Exchange Rate',
  filterProposals: 'Filter proposals',
  governance: 'Governance',
  inactiveValidatorsList: 'Inactive validators list',
  initialBalance: 'Initial balance',
  keys: 'Keys',
  lastBlocks: 'Last {0} blocks',
  lastTxs: 'Last {0} transactions',
  latestHeight: 'Latest height',
  liquidityPool: 'Liquidity pool',
  marketingDetail: 'Marketing detail',
  membership: 'Membership',
  message: 'Message',
  metadata: 'Metadata',
  mintTokens: 'Mint tokens',
  mintingDetail: 'Minting detail',
  msgsTxs: 'Msgs/txs',
  myValidators: 'My validators',
  newAsset: 'New asset',
  otherTokensBalance: 'Other tokens balance',
  pools: 'Pools',
  poolsDetail: 'Pools detail',
  poolsList: 'Pools list',
  price: 'Price',
  proof: 'Proof',
  proposalDetail: 'Proposal detail',
  proposalsByType: 'Proposals by type',
  qrCode: 'QR Code',
  recipients: 'Recipients',
  remainingBlocks: 'Remaining blocks',
  searchBlocks: 'Search blocks',
  searchTxs: 'Search txs',
  searchValidator: 'Search validator',
  sendTokens: 'Send tokens',
  service: 'Service',
  sharedocDetail: 'Sharedoc detail',
  statisticsVotes: 'Statistics votes',
  swap: 'Swap',
  technicalInfo: 'Technical info',
  timeoutHeight: 'Timeout height',
  total: 'Total',
  transactionDetail: 'Transaction detail',
  transactions: 'Transactions',
  transactionsAtHeight: 'Transactions at {0} height',
  trend: 'Trend',
  txType: 'Transaction type',
  txsBlocks: 'Txs/blocks',
  txsBySender: 'Transactions by msg sender',
  unbondingDelegations: 'Unbonding delegations',
  validatorDetail: 'Validator detail',
  validators: 'Validators',
  validatorsList: 'Validators list',
  votingDetail: 'Voting detail',
  withdraw: 'Withdraw',
};
