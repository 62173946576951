export const titles = {
  accountBalance: 'Account balance',
  accountDetail: 'Dettagli account',
  accountsBalance: 'Accounts balance',
  activeValidatorsList: 'Lista validatori attivi',
  allBalances: 'All balances',
  allTokensBalance: 'Saldo token',
  allWallets: 'All wallets',
  assetDetail: 'Dettagli asset',
  assetTransfer: 'Trasferimento asset',
  assets: 'Assets',
  blockDetail: 'Dettagli blocco',
  blocks: 'Blocchi',
  blocksFromTo: 'Blocchi da {0} a {1}',
  cccExchangeRate: 'Tasso di cambio CCC',
  checksum: 'Checksum',
  code: 'Codice',
  comCapitalization: 'Capitalizzazione COM',
  commission: 'Commissione',
  contractDetail: 'Dettagli contratto',
  countdownForBlock: 'Countdown per il bloccco',
  dashboard: 'Dashboard',
  delegations: 'Delegazioni',
  delegatorAmounts: 'Importi delegatore',
  deposit: 'Deposito',
  description: 'Descrizione',
  dex: 'Dex',
  dexDetail: 'Dettagli Dex',
  dexList: 'Elenco Dex',
  doSign: 'Firma',
  editLogo: 'Modifica logo',
  editMarketing: 'Modifica marketing',
  exchangeRate: 'Tasso di cambio',
  filterProposals: 'Filtra proposte',
  governance: 'Governo',
  inactiveValidatorsList: 'Lista validatori inattivi',
  initialBalance: 'Saldo iniziale',
  keys: 'Chiavi',
  lastBlocks: 'Ultimi {0} blocchi',
  lastTxs: 'Ultime {0} transazioni',
  latestHeight: 'Ultima altezza',
  liquidityPool: 'Liquidity pool',
  marketingDetail: 'Dettaglio marketing',
  membership: 'Membership',
  message: 'Messaggio',
  metadata: 'Metadata',
  mintTokens: 'Mint tokens',
  mintingDetail: 'Dettagli minting',
  msgsTxs: 'Msgs/txs',
  myValidators: 'Miei validatori',
  newAsset: 'Nuovo asset',
  otherTokensBalance: 'saldo altri token',
  pools: 'Pools',
  poolsDetail: 'Dettagli pool',
  poolsList: 'Elenco pool',
  price: 'Prezzo',
  proof: 'Prova',
  proposalDetail: 'Dettagli proposta',
  proposalsByType: 'Proposte per tipo',
  qrCode: 'QR Code',
  recipients: 'Destinatari',
  remainingBlocks: 'Blocchi rimanenti',
  searchBlocks: 'Cerca blocchi',
  searchTxs: 'Cerca txs',
  searchValidator: 'Cerca validatore',
  sendTokens: 'Invia token',
  service: 'Service',
  sharedocDetail: 'Dettagli Sharedoc',
  statisticsVotes: 'Statistiche voti',
  swap: 'Swap',
  technicalInfo: 'Informazioni tecniche',
  timeoutHeight: 'Timeout height',
  total: 'Totale',
  transactionDetail: 'Dettagli transazione',
  transactions: 'Transazioni',
  transactionsAtHeight: 'Transazioni ad altezza {0}',
  trend: 'Trend',
  txType: 'Tipo di transazione',
  txsBlocks: 'Txs/blocks',
  txsBySender: 'Transazioni per mittente',
  unbondingDelegations: 'Unbonding di delegazioni',
  validatorDetail: 'Dettagli validatore',
  validators: 'Validatori',
  validatorsList: 'Lista validatori',
  votingDetail: 'Dettaglio votazione',
  withdraw: 'Prelievo',
};
