export const msgs = {
  accessDenied: 'Access denied',
  accountNotExist: 'Account does not exist',
  addingAMarketer: 'Adding a marketing manager is mandatory in order to change marketing in the future',
  addingAMinter: 'Adding a minter is mandatory in order to mint further tokens',
  amonutLessThanBalance: 'The amount must be less than the balance',
  amountGreaterZero: 'Amount must be greater than or equal to zero',
  amountVsCap: 'If a cap, amount must be less than or equal to cap',
  assertAndShowHash: 'The transaction was successful and the hash is:',
  assertAndViewTx: 'The transaction was successful and can be viewed at this link',
  bannerPrimary: 'Commercio.network: The Digital Transformation Blockchain',
  bannerSecondary: 'Create an ID, sign and exchange documents on the first eIDAS compliant Blockchain in Europe',
  comingSoon: 'Coming soon',
  connected: 'connected',
  copy: 'Copy',
  cw20SymbolPolicies: 'The symbol must be a min of 3 chars and a max of 12, and may contain only upper and lower case letters and the hyphen',
  dataNotAvailable: 'At this point in time the data, unfortunately, is not available. Please refresh the page.',
  decimalField: 'This field accepts only decimal numbers',
  delegateAdvice1: 'Staking will lock your funds for 21 days.',
  delegateAdvice2: 'You will need to undelegate in order for your staked assets to be liquid again.',
  delegateAdvice3: 'This proccess will take 21 days to complete.',
  dropYourFile: 'Drop your file here, or click to select it.',
  estimatedTime: 'Estimated time',
  exchangeRateCalc: '1 divided the % of Circulating Supply',
  failed: 'Failed',
  failedToDetectTheChain: 'Failed to detect the chain',
  failedToSuggestTheChain: 'Failed to suggest the chain',
  filesCorrespond: 'The file\'s footprint(Hash) you submitted correspond to the notarization in blockchain.',
  filesNotCorrespond: 'The file\'s footprint(Hash) you submitted DO NOT correspond to the notarization in blockchain.\nPlease check the file you submitted is the right one.',
  filterByStatus: 'Filter by status',
  filterValidatorsInfo: 'Filter list by status',
  installKeplrExtension: 'Please install Keplr extension',
  listPrice: 'List price',
  loading: 'Loading...',
  lostBlocks: 'Lost {0} in 20K',
  mandatoryField: 'This field is mandatory',
  noAccountFound: 'No accounts found. Use the Keplr extension to create it or import it.',
  noCode: 'No code available',
  noMessage: 'No message available',
  noWalletConnected: 'No wallet connected',
  notAvailable: 'Not available',
  notFound: 'Not found',
  placeholderSearchBar: 'Search Address, Block Height, TxHash',
  positiveAmount: 'The amount must be positive',
  proposalMinimumStaked: 'Minimum of {0} COM ({1}) staked COM need to vote for this proposal to pass',
  scan: 'Scan',
  scanAddress: 'Scan the address',
  searchBlocksInfo: 'Search {0} blocks in descending order starting from an height (min {1})',
  searchValidatorsInfo: 'Search validator by name, address and linked account',
  startTypingToFilterTypes: 'Start typing to filter types',
  success: 'Success',
  tooLongField: 'This field is too long',
  triggeredAnError: 'Something happened in setting up the request that triggered an error',
  unansweredRequest: 'The request was made but no response was received',
  undelegateAdvice: 'Once you undelegate you will not receive staking rewards or be able to cancel the unbonding , need to wait 21 day for the amount to be liquid.',
  underMaintenance: 'We are working on this, please come back later!',
  unsupportedAlgorithm: 'The algorithm is not supported',
  validatorNotExist: 'Validator does not exist',
  verificationFailed: 'Bad!!!  Verification failed.',
  verificationSuccess: 'Great!!! Verification success.',
  workInProgress: 'Work in progress',
  youVoted: 'You voted "{0}"',
};
