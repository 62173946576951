import { TRANSACTIONS } from '@/constants';

export const labels = {
  [TRANSACTIONS.MULTI_FEE]: 'Multi fees',
  [TRANSACTIONS.MULTI_TYPE]: 'Multi types',
  abrAndVbrRewardsNotDistributed: 'ABR and VBR rewards not distributed',
  abrTokensDistributed: 'ABR tokens distributed',
  abrTokensNotDistributed: 'ABR tokens not distributed',
  abstain: 'Abstain',
  active: 'Active',
  address: 'Address',
  admin: 'Admin',
  algorithm: 'Algorithm',
  amount: 'Amount',
  assertionMethod: 'Assertion method',
  authentication: 'Authentication',
  availables: 'Availables',
  balance: 'Balance',
  blocks: 'Blocks',
  blocksPercentage: 'Blocks %',
  bonded: 'Bonded',
  bondedTokens: 'Bonded tokens',
  burned: 'Burned',
  buyer: 'Buyer',
  cap: 'Cap',
  capabilityDelegation: 'Capability delegation',
  capabilityInvocation: 'Capability invocation',
  caption: 'Caption',
  certificateProfile: 'Certificate profile',
  chain: 'Chain',
  changes: 'Changes',
  circulatingSupply: 'Circulating supply',
  claimRewards: 'Claim rewards',
  codeId: 'Code Id',
  comAmount: 'COM amount',
  comTotal: 'COM Total',
  commission: 'Commission',
  communityTokensDistributed: 'Community tokens distributed',
  communityTokensNotDistributed: 'Community tokens not distributed',
  completionDate: 'Completion date',
  confirm: 'Confirm',
  connectWallet: 'Connect wallet',
  content: 'Content',
  contentUri: 'Content URI',
  context: 'Context',
  contract: 'Contract',
  contractAddress: 'Contract address',
  controller: 'Controller',
  conversionRate: 'Conversion rate',
  created: 'Created',
  cumulative: 'Cumulative',
  date: 'Date',
  days: 'Days',
  decimals: 'Decimals',
  delegated: 'Delegated',
  delegatedTokens: 'Delegated tokens',
  delegations: 'Delegations',
  delegator: 'Delegator',
  delegatorAddress: 'Delegator address',
  denom: 'Denom',
  deposit: 'Deposit',
  depositEndTime: 'Deposit end time',
  depositor: 'Depositor',
  description: 'Description',
  destinationValidatorAddress: 'Destination validator address',
  detail: 'Detail',
  did: 'Did',
  documentUUID: 'Document UUID',
  earning: 'Earning',
  editVote: 'Edit vote',
  encryptionDataEncryptedData: 'Encryption data encrypted data',
  encryptionDataKeys: 'Encryption data keys',
  exchangeRate: 'Exchange rate',
  expectedAmount: 'Expected amount',
  failed: 'Failed',
  fee: 'Fee',
  filter: 'Filter',
  freezePeriod: 'Freeze period',
  freezed: 'Freezed',
  freezedTokens: 'Freezed tokens',
  fromAddress: 'From address',
  fromToken: 'From token',
  funds: 'Funds',
  gasUsedWanted: 'Gas (Used/Wanted)',
  getOnline: 'Get online',
  government: 'Government',
  hash: 'Hash',
  height: 'Height',
  hours: 'Hours',
  id: 'id',
  identity: 'Identity',
  inactive: 'Inactive',
  info: 'Info',
  instantiateContract: 'Instantiate contract',
  instantiatePermission: 'Instantiate permission',
  key: 'Key',
  keyAgreement: 'Key agreement',
  label: 'Label',
  lang: 'Lang',
  lastUpdate: 'Last update: {0}',
  lbrTokensDistributed: 'LBR Tokens Distributed',
  lbrTokensNotDistributed: 'LBR Tokens Not Distributed',
  liquidityPoolTokensDistributed: 'Liquidity pool tokens distributed',
  liquidityPoolTokensNotDistributed: 'Liquidity pool tokens not distributed',
  logo: 'Logo',
  logoUrl: 'Logo URL',
  manage: 'Manage',
  marketing: 'Marketing',
  max: 'Max',
  maxChangeRate: 'Max change rate',
  maxRate: 'Max rate',
  maxSupply: 'Max supply',
  membership: 'Membership',
  membershipType: 'Membership type',
  memo: 'Memo',
  metadataContentUri: 'Metadata content uri',
  metadataSchemaType: 'Metadata schema type',
  metadataSchemaUri: 'Metadata schema uri',
  metadataSchemaVersion: 'Metadata schema version',
  minSelfDelegation: 'Min self delegation',
  minter: 'Minter',
  minutes: 'Minutes',
  moniker: 'Moniker',
  moreInfo: 'More info',
  msg: 'Message',
  msgType: 'Message type',
  msgs: 'Msgs',
  msgsOnTxs: 'Msgs/Txs',
  multiTypes: 'Multi types',
  multiValues: 'Multi values',
  myValidators: 'My validators',
  name: 'Name',
  newMembership: 'New membership',
  no: 'No',
  noWithVeto: 'No with veto',
  nonCirculatingSupply: 'Non circulating supply',
  none: 'None',
  not: 'Not',
  notBonded: 'Not bonded',
  notEarning: 'Not earning',
  officialNode: 'Official node (raw json)',
  operator: 'Operator',
  others: 'Others',
  percentage: 'Percentage',
  plan: 'Plan',
  project: 'Project',
  proof: 'Proof',
  proofPurpose: 'Proof purpose',
  proposer: 'Proposer',
  publicKey: 'Public key',
  publicKeyMultibase: 'PublicKey Multibase',
  publicKeyPem: 'Public key pem',
  quantity: 'Quantity',
  rank: 'Rank',
  rate: 'Rate',
  rawLog: 'Raw log',
  received: 'Received',
  receivedTxs: 'Received transactions',
  receiver: 'Receiver',
  recipient: 'Recipient',
  redelegable: 'Redelegable',
  redelegateFrom: 'Redelegate from',
  refresh: 'Refresh',
  reserve: 'Reserve',
  reset: 'Reset',
  result: 'Result',
  reverse: 'Reverse',
  revisionHeight: 'Revision height',
  revisionNumber: 'Revision number',
  rewards: 'Rewards',
  save: 'Save',
  sdnData: 'SDN data',
  search: 'Search',
  searchHeight: 'Search height',
  searchNameAddressAccount: 'Search name, address, account',
  seconds: 'Seconds',
  securityContact: 'Security contact',
  select: 'Select',
  selectDex: 'Select a DEX',
  self: 'Self',
  send: 'Send',
  sender: 'Sender',
  sent: 'Sent',
  sentTxs: 'Sent transactions',
  service: 'service',
  serviceEndpoint: 'Service endpoint',
  share: 'Share',
  showAll: 'Show all',
  signatureValue: 'Signature value',
  signer: 'Signer',
  signerInstance: 'Signer instance',
  sourceChannel: 'Source channel',
  sourcePort: 'Source port',
  sourceValidatorAddress: 'Source validator address',
  status: 'Status',
  storageUri: 'Storage uri',
  submitTime: 'Submit time',
  subscriber: 'Subscriber',
  subspace: 'Subspace',
  subtotal: 'Subtotal',
  success: 'Success',
  supply: 'Supply',
  swap: 'Swap',
  symbol: 'Symbol',
  time: 'Time',
  timeoutTimestamp: 'Timeout timestamp',
  title: 'Title',
  toAddress: 'To address',
  toToken: 'To token',
  token1: 'Token1',
  token2: 'Token2',
  tokens: 'Tokens',
  tokensPerformance: 'tokens performance',
  total: 'Total',
  totalCirculatingSupply: 'Total circulating supply',
  totalNonCirculatingSupply: 'Total non circulating supply',
  transfer: 'Transfer',
  tsp: 'Tsp',
  turnout: 'Turnout',
  txHash: 'Tx hash',
  txs: 'Txs',
  txsAmount: 'Txs amount',
  type: 'Type',
  udetails: 'Udetails',
  unbondings: 'Unbondings',
  unclaimedRewards: 'Unclaimed rewards',
  unreleasedRewards: 'Unreleased rewards',
  updateTime: 'Update time',
  upgradedClientState: 'Upgraded client state',
  urlLogo: 'URL logo',
  uuid: 'UUID',
  validator: 'Validator',
  validatorAddress: 'Validator address',
  validatorTokensDistributed: 'Validator tokens distributed',
  validatorTokensNotDistributed: 'Validator tokens not distributed',
  validators: 'Validators',
  value: 'Value',
  vbrTokensDistributed: 'VBR tokens distributed',
  vbrTokensNotDistributed: 'VBR tokens not distributed',
  vcrId: 'VCR id',
  verificationMethod: 'Verification method',
  verifyHash: 'Verify hash',
  vote: 'Vote',
  voter: 'Voter',
  votingEndTime: 'Voting end time',
  votingPower: 'Voting power',
  votingStartTime: 'Voting start time',
  wallet: 'Wallet',
  wasmByteCode: 'Wasm bytecode',
  website: 'Website',
  withdraw: 'Withdraw',
  yes: 'Yes',
};
